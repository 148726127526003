import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { Content } from 'components/content/Content';

import BeerCollage from 'components/beer-collage/BeerCollage';
import Beer from 'components/beer-collage/Beer';

export default () => (
  <>
    <Helmet title="Home" />

    <Content
    />
  </>
);
